import React from 'react';
import LandingPage from 'views/LandingPage';
import AboutPage from 'views/AboutPage';
import ServicesPage from 'views/ServicesPage';
import ReservationPage from 'views/ReservationPage';
import ContactPage from 'views/ContactPage';
import Footer from 'views/Footer';
import Helmet from "react-helmet";
import customer from 'customer.json';
import "assets/scss/material-kit-pro-react.scss?v=1.9.0";

export default function App() {
  let city = customer.address.split(' ').splice(4, 1).join()
  let state = customer.address.split(' ').splice(5, 1).join()
  let zipCode = customer.address.split(' ').splice(6, 1).join()


  return (
    <>
      <Helmet>
        {/* <!-- HTML Meta Tags --> */}
        <title>{customer.name} Muskego</title>
        <meta name="title" content={`${customer.name} | ${city} ${state} Online Booking Nail Spa`} />
        <meta
          name="description"
          content={`Here at ${customer.name}, we love what we do. Our goal is to have every client walk away feeling more beautiful and confident. Support local business in ${city} ${state} `} />
        <meta name="keywords"
          content={`${customer.name}, restaurant near me, food near me, online order, Vietnamese food, pho, banh mi, sandwich, food in ${city} ${state}, best Vietnamese food, best pho, best restaurant, restaurant ${zipCode} carry out, delivery, takeout, food order online, family owned`} />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content={`${customer.name} | ${city} ${state} Online Booking Nail Spa`} />
        <meta
          name="description"
          content={`Here at ${customer.name}, we love what we do. Our goal is to have every client walk away feeling more beautiful and confident. Support local business in ${city} ${state} `} />
        <meta itemprop="image"
          content={`https://imagestorage-businesswebsite.s3.us-east-2.amazonaws.com/thumbnail/${customer.businessID}.jpg`}
        />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:title" content={`${customer.name} | ${city} ${state} Online Booking Nail Spa`} />
        <meta property="og:description"
          content={`Here at ${customer.name}, we love what we do. Our goal is to have every client walk away feeling more beautiful and confident. Support local business in ${city} ${state} `}
        />
        <meta property="og:image"
          content={`https://imagestorage-businesswebsite.s3.us-east-2.amazonaws.com/thumbnail/${customer.businessID}.jpg`} />
        <meta property="og:url" content={customer.website} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta property="twitter:title" content={`${customer.name} | ${city} ${state} Online Ordering Vietnamese Food Restaurant`} />
        <meta
          name="twitter:description"
          content={`Here at ${customer.name}, we love what we do. Our goal is to have every client walk away feeling more beautiful and confident. Support local business in ${city} ${state} `}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:image"
          content={`https://imagestorage-businesswebsite.s3.us-east-2.amazonaws.com/thumbnail/${customer.businessID}.jpg`}
        />
      </Helmet>
      <LandingPage />
      <AboutPage />
      {/* <ServicesPage /> */}
      <ReservationPage />
      <ContactPage />
      <Footer />
    </>
  )
}


